import styled from "styled-components"

import { MountStatus } from "src/components/Devices/MountStatus"
import { BatteryAndChargeStatusIcon } from "src/components/Homes/DeviceDetails/BatteryAndChargeStatusIcon"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { NetworkIcon } from "src/ui/icons/NetworkIcon"
import { spacing } from "src/ui/spacing"

export function DeviceMonitoringStatus({ device }: { device: TDevice }) {
  const deviceType = getDeviceHardwareType(device.hardware_version).type

  return (
    <Box>
      <NetworkIcon device={device} />

      <BatteryAndChargeStatusIcon
        percent={device.battery?.percent}
        chargeStatus={device.charge_status}
        offline={device.offline}
        width={30}
      />

      <MountStatus
        status={device.mount_status}
        deviceType={deviceType}
        offline={device.offline}
        width={30}
        height={30}
      />
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  gap: ${spacing.S};
`
