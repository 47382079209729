import { SvgIconProps, Tooltip } from "@material-ui/core"

import { TSignalQuality } from "src/data/devices/types/deviceTypes"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import CellExcellent from "src/ui/icons/cellular-excellent.svg"
import CellFair from "src/ui/icons/cellular-fair.svg"
import CellGood from "src/ui/icons/cellular-good.svg"
import CellNone from "src/ui/icons/cellular-none.svg"
import CellWeak from "src/ui/icons/cellular-weak.svg"
import { SVGElement } from "src/utils/tsUtil"

// eslint-disable-next-line import/no-default-export
export default function Cellular({
  quality,
  offline = false,
  ...props
}: {
  quality: TSignalQuality
  offline: boolean
} & SvgIconProps) {
  const { t } = useTranslate()

  const cellular = offline
    ? { title: t(langKeys.offline), svg: CellNone }
    : getIconMapping(quality, t)

  const CellularIcon = cellular.svg

  return (
    <Tooltip title={cellular.title}>
      <div>
        <CellularIcon height={30} width={30} {...props} />
      </div>
    </Tooltip>
  )
}

function getIconMapping(quality: TSignalQuality, t: ITranslateFunction) {
  const iconMapping: { title: string; svg: SVGElement }[] = [
    { title: t(langKeys.signal_none), svg: CellWeak },
    { title: t(langKeys.signal_weak), svg: CellWeak },
    { title: t(langKeys.signal_fair), svg: CellFair },
    { title: t(langKeys.signal_good), svg: CellGood },
    { title: t(langKeys.signal_excellent), svg: CellExcellent },
  ]

  return iconMapping[Number(quality) || 0]
}
