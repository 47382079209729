import { SvgIconProps } from "@material-ui/core"

import { TDevice } from "src/data/devices/types/deviceTypes"
import { WifiIcon } from "src/ui/WifiIcon/WifiIcon"

import CellularIcon from "./CellularIcon"
import { EthernetIcon } from "./EthernetIcon"

export function NetworkIcon({
  device,
  ...props
}: {
  device?: TDevice
} & SvgIconProps) {
  switch (device?.network_interface) {
    case "wifi":
      return (
        <WifiIcon
          quality={device.wlan?.signal_quality}
          offline={device.offline}
          {...props}
        />
      )
    case "cellular":
      return (
        <CellularIcon
          quality={device.cellular?.signal_quality}
          offline={device.offline}
          {...props}
        />
      )
    case "ethernet":
      return <EthernetIcon offline={device.offline} {...props} />

    default:
      // If device is undefined or network interface not handled
      return <WifiIcon quality={0} offline={false} {...props} />
  }
}
